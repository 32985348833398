.cardItem {
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
    margin: 20px;
    width: 240px;
    height: 360px;
    transition: all 0.3s ease-out;    
  }

  .cardItem:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
   
  .cardItem h2 {
    color: #006134;
    font-size: 26px;
    padding-top: 20px;
    margin: 0px auto;
  }

  .cardItem p {
    color: #000000;
    font-size: 16px;
    padding: 10px;
    padding-top: 40px;
  }
   
  .imageCard {
    max-width: 100%;
    position: relative;

  }

  .blueCard {
    border-right: 3px solid #4895ff;
  }
   
  .greenCard {
    border-right: 3px solid #3bb54a;
  }
   
  .redCard {
    border-right: 3px solid #b3404a;
  }