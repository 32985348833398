nav{
    position: fixed;
    width: 100%;  
    display: flex;
    justify-content: space-around;
    height: 80px;
    transition: all ease 0.9s;  
    border-bottom: 5px solid rgba(141, 141, 141, 0.151);
    background-color: rgba(255, 255, 255, 0.781);   
}

.nav-menu{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
}

nav ul{
    display: flex;
}
nav li{
    list-style: none;
    padding: 0px 5px;
}
nav li a {
    text-decoration: none;
    font-size: 14px;
    color: rgb(0, 0, 0);
    opacity: 1;    
}
nav li a:hover{
    opacity: 0.7;
}
nav li a:active{
    opacity: 0.7;
    color: white;
}
nav li a img{
    max-width: 32px;    
}

.nav-toggler div{
    width: 30px;
    height: 0.2rem;
    margin: 0.4rem;
    margin-right: 15px;
    background: rgb(110, 110, 110);
    transition: 0.4s ease-in;    
    
}

.nav-toggler{
    cursor: pointer;
    display: none;
    padding-top: 10px;        
}


.menu{
    background-color: rgba(13, 65, 43, 0.144);   
    padding: 80px 30px px;
    margin: 0;
    //background-image: url('../../assets/imagens/back.png');
}


.logo img{
    width: 45%;
}

.ativaCor{
    //background-color: rgba(2, 116, 63, 0.548);
    background-color: rgba(218, 218, 218, 0.678);   
    //background-image: url('../../assets/imagens/back.png');
}
.ativaCor li a{
    color: rgb(27, 27, 27);
}



@media screen and (max-width: 768px){ 
    .header{
        display: none;
    }   
    .nav-toggler{    
        display: block;       
    }
    .nav-menu{    
        position: fixed;
        padding-top: 40px;
        margin: 0px auto;
        right: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 48, 4, 0.815);
        flex-direction: column;
        transform: translateX(100%);
        transition: 0.5s ease-in;
    }
    .navbar li{
        list-style: none;
        padding: 0px 0px;
        margin: 0 auto;
    }
    nav li a {
        font-size: 15px;
        color: rgb(255, 255, 255);
        opacity: 1;    
    }
    nav{ 
        border: none;
        justify-content: right;
        margin: 0 auto;
        width: 100%;
        background-color: rgba(255, 255, 255, 0); 
    }    
}



.nav-active{
    transform: translateX(0);    
}

.toggle .line1{
    transform: rotate(-45deg) translate(-9px, 5px); 
    background:white;
}
.toggle .line2{
    opacity: 0;
}
.toggle .line3{
    transform: rotate(45deg) translate(-9px, -5px); 
    background:white;
}