.mapa{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

#localizacao{
    padding-top: 50px;
}

.mapa iframe{
    width: 100%;
    height: 400px;
}

.mapa p{
    padding-bottom: 20px;
    color: rgb(66, 66, 66);
    max-width: 500px;
}

.pLocal{
    margin-top: -20px;
    font-weight: bold;
}